import React from "react";
import "./footer.scss";
import FooterLogo from "../../assets/images/logo.jpg";
function Footer() {
    return (
        <>
           <footer className="footer-main">
                <div className="container">
                <div className="footer-container">
                    <div className="footer-content">
                        <div className="company-info">
                            <div className="footer-image">
                                <a className="logo" href="/"><img src={FooterLogo} alt="footer-logo" className="img-fluid" />
                                </a>
                            </div>
                            <div className="footer-btn btn-shadow">
                                <a href="/contact" className="cta-button">Tap To Get in Touch</a>
                            </div>
                        </div>
                        <div className="company-details">
                            <p>
                                Address: Office B01, Liwa Center - Tower B, Sh. Hamdan Street, Abu Dhabi
                            </p>
                            <div className="contact-no">
                                <span>Phone:</span>&nbsp;
                                <a href="tel:+97128826167">+971 2 8826167</a>
                            </div>
                            <div className="contact-wrap">
                            <div className="company-email">
                                <span>Email:</span>&nbsp;
                                <a href="mailto:info@veritechengineeringservices.com">
                                    info@veritechengineeringservices.com
                                </a>
                            </div>
                            <div className="company-web">
                                <span>Website:</span>&nbsp;
                                <a href="http://www.veritechengineeringservices.com/" className="company-website">
                                    www.veritechengineeringservices.com
                                </a>
                            </div>
                            </div>
                        </div>
 
                    </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default Footer;
